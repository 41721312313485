<template>
  <div class="Auth hero is-fullheight">
    <div class="columns is-align-items-stretch hero-body p-0 m-0">
      <div class="column p-0 is-half-tablet Auth__left is-hidden-mobile"
           :style="{backgroundImage: `url(${require(`@/assets/img/hero-banner.svg`)})`}">
      </div>
      <div class="Auth__right column is-flex is-align-items-center is-justify-content-center is-half-tablet p-6">
        <div class="Auth__wrapper">
          <h1 class="title is-1 has-text-weight-bold">Lotti</h1>
          <article v-if="error" class="message is-danger">
            <div class="message-body">
              {{ error }}
            </div>
          </article>
          <form @submit.prevent="loginHandle()">
            <div class="field mb-4">
              <p class="control has-icons-left has-icons-right">
                <input v-model="email" class="input is-large is-primary" type="email" placeholder="Email" required>
                <span class="icon is-small is-left">
                  <unicon name="envelope" fill="black"/>
              </span>
              </p>
            </div>
            <div class="field mb-5">
              <p class="control has-icons-left">
                <input v-model="password" class="input is-large is-primary" type="password" placeholder="Password" required>
                  <span class="icon is-small is-left">
                <unicon name="key-skeleton" fill="black"/>
              </span>
              </p>
            </div>
            <div class="field">
              <p class="control">
                <button type="submit" :disabled="loading" class="button is-success is-large">
                  <loader v-if="loading" :fill-color="'white'"/>
                  <span v-if="!loading">Login</span>
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMutation } from "@vue/apollo-composable";
import { LOGIN_MUTATION } from "@/graphql/mutations/authMutation";
import { defineAsyncComponent, onMounted, ref } from "vue";
import useAuth from "@/use/useAuth";
import router from "@/router";
import { useUser } from "@/use/useUser";
import { getUserAPI } from "@/use/useGitlabApi"

export default {
  components: {
    Loader: defineAsyncComponent(() => import("@/components/widgets/loader"))
  },
  setup() {
    const email = ref('')
    const password = ref('')

    onMounted(() => {
      document.body.classList.remove('has-navbar-fixed-top');
    })

    const { setCurrentUser, setCurrentPermissions, setCurrentUserData} = useUser()
    const { setToken, setAuthenticated } = useAuth()
    const { mutate: login, error, onDone, loading } = useMutation(
        LOGIN_MUTATION,
        () => ({
          clientId: 'cmsClient',
        }));

    const loginHandle = () => {

      if (!email.value || !password.value) {
        return
      }

      login({ email: email.value, password: password.value })

      onDone(  async ({data}) => {

        console.log(data)

        let { user } = data.login

        const rs = await getUserAPI(user.gitlab_id)

        if (rs) {
          // avatar_url
          let payload = { avatar_url: rs.avatar_url }
          setCurrentUserData(payload);
        }

        setToken(data.login.token)
        setAuthenticated()
        setCurrentPermissions(data.login.permissions)
        setCurrentUser(data.login.user)

        router.push('/')
      })
    }

    return {
      loginHandle,
      email,
      password,
      error,
      loading
    }
  }
}
</script>

<style lang="scss">
.Auth {
  &__left {
    background-position: center;
    background-size: cover;
  }

  form {
    width: 100%;

    .unicon {
      display: flex;
      align-items: center;
    }
  }

  &__right {
    max-width: 100%;
  }

  &__wrapper {
    width: 600px;
    max-width: 100%;
  }
}
</style>
